<template>
  <v-slide-item>{{ date }}</v-slide-item>
</template>

<script>
export default {
    name: 'HorizontalDateItem',
    props: {
      date: {
        type: Date,
        default: new Date()
      }
    }
}
</script>